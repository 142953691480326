 /* Style the navbar */
 #navbar {
  overflow: hidden;
  background-color: #333;
  position: fixed;  
  top: 0;
  width: 100%;
  z-index: 20;
}

/* Navbar links */
#navbar a {
  float: left;
  display: block;
  color: red;
  background-color: #333;
  text-align: center;
  padding: 14px;
  text-decoration: none;
  transition :1.5s;
  cursor: pointer;
  
}
#navbar a:hover {
  color: aqua;
  font-size: 30px;

}
#navbar img
{
  height: 60px;
  background-color: #333;
  padding-right: 10%;
  float:right; 
  transition :1.5s;
}
#navbar img:hover
{
  height: 85px;
}

.footer{
  text-align: center;
  background: #222;
  height: 150px;
  padding: 50px;
  
}
.footer p{
  border-bottom: 1px solid #666;
    color: #eee;
    text-align:center;
    font-size: 20px;
    text-decoration: none;
    background: #222

}